import React from "react";
import {Container, Row} from "react-bootstrap";
import {Outlet} from "react-router-dom";
import Navigation from "./Navigation";
import "./Layout.css"
import {Toaster} from "react-hot-toast";

export default () => {
    return <>
        <Toaster position="top-right" />
        <Container fluid className="min-vh-100">
            <Row className="min-vh-100">
                <Navigation />
                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                    <Outlet />
                </main>
            </Row>
        </Container>
    </>;
}