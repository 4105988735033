import * as yup from "yup";

const bankSchema = required => yup.object().shape({
    rented: yup.boolean(),
    name: yup.string()
        .min(3, "Must be at least 3 characters")
        .max(15, "Must not exceed 15 characters")
        .matches(/^[a-zA-Z0-9]*$/, "Must only contain alphanumeric.")
        .nullable()
        .transform((_, x) => x === '' ? null : x)
        .test(
            "required-if-not-rented",
            "Required when not rented",
            (value, context) => !required || value || context.parent.rented
        )
});

export const hiveSchema = {
    clientName: yup.string()
        .required("Required")
        .max(50, "Must not exceed 50 characters")
        .matches(/^[a-zA-Z0-9-_ ]*$/, "Must only contain alphanumeric, dash or underscore."),
    hiveName: yup.string()
        .required("Required")
        .max(50, "Must not exceed 50 characters")
        .matches(/^[a-zA-Z0-9-_ ]*$/, "Must only contain alphanumeric, dash or underscore."),
    banks: yup.object().shape({
        primary: bankSchema(true),
        secondary: bankSchema(false)
    }),
    allianceName: yup.string()
        .min(5, "Must be at least 5 characters")
        .max(20, "Must not exceed 20 characters")
        .matches(/^[a-zA-Z0-9]*$/, "Must only contain alphanumeric.")
        .nullable()
        .transform((_, x) => x === '' ? null : x),
    location: yup.object().shape({
        z: yup.number().typeError("Must be a number").required("Required"),
        x: yup.number().typeError("Must be a number").required("Required"),
        y: yup.number().typeError("Must be a number").required("Required")
    })
};
