/* eslint-disable no-undef */
function auth() {
    const url = `${window.location.origin}/auth/callback`
    return fetch(`/api/oauth/auth?callback=${encodeURIComponent(url)}`)
        .then(checkStatus)
        .then(parseJSON);
}

function check() {
    return get("/api/oauth/check");
}

function token(params) {
    return get(`/api/oauth/token${params}`);
}

function logout() {
    return get(`/api/oauth/logout`);
}

function run(script, arg) {
    return post(`/api/backend/${script}`, arg);
}

function hives() {
    return get(`/api/hives`);
}

function hiveById(id) {
    return get(`/api/hives/${id}`);
}

function createHive(data) {
    return post(`/api/hives`, data);
}

function destroyHive(id) {
    return del(`/api/hives/${id}`);
}

function repairHive(id) {
    return post(`/api/hives/${id}/repair`, id);
}

function updateHive(id, data) {
    return patch(`/api/hives/${id}`, data);
}

function jobs() {
    return get(`/api/jobs`);
}

function jobStart(id) {
    return post(`/api/jobs/${id}/start`);
}

function jobStop(id) {
    return post(`/api/jobs/${id}/stop`);
}

function get(url) {
    return fetch(url)
        .then(checkAuth)
        .then(checkStatus)
        .then(parseJSON);
}

function del(url) {
    return fetch(url, { method: "DELETE" })
        .then(checkAuth)
        .then(checkStatus)
        .then(parseJSON);
}

function patch(url, data) {
    return fetch(url, {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    })
        .then(checkAuth)
        .then(checkStatus)
        .then(parseJSON);
}

function post(url, data) {
    return fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    })
        .then(checkAuth)
        .then(checkStatus)
        .then(parseJSON);
}

function checkAuth(response) {
    return new Promise(async (resolve, reject) => {
        if (response.status === 401) {
            const { url } = await auth();
            window.location.assign(url);
        }
        else resolve(response);
    })
}

function checkStatus(response) {
    const s = response.status;
    if (s >= 200 && s < 300 || s === 409) {
        return response;
    }
    const error = new Error(`HTTP Error ${response.statusText}`);
    error.status = response.statusText;
    error.response = response;
    throw error;
}

function parseJSON(response) {
    return response.json();
}

const Client = { auth, check, token, logout, run, hives, hiveById, createHive, destroyHive, repairHive, updateHive, jobs, jobStop, jobStart };
export default Client;