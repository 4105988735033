import Client from "../remote/Client";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { render } from "../util/location";
import { numberFormat } from "../util/formats";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import "./Create.css";
import {parseMetric} from "../util/numeral";
import {hiveSchema} from "../util/schema";

const resourceRatioKeys = ["earth", "corn", "quartz", "alloy"];

const schema = yup.object({
    ...hiveSchema,
    resource: yup.object().shape({
        total: yup.string()
            .required("Required")
            .matches(/^[0-9]+[km]?$/i, "Must be number with optional suffix of M or K"),
        gas: yup.boolean()
    }),
    termLength: yup.number()
        .required()
        .min(1, "Must be at least 1")
        .max(60, "Must not exceed 60")
});

const defaultValues = {
    termLength: 30,
    resource: {
        alloy: 25,
        corn: 25,
        quartz: 25,
        earth: 25,
        gas: true
    }
};

const useRerender = () => {
    const [version, setVersion] = useState(0);
    return () => {
        setVersion(version + 1);
    };
};

const Edit = ({data, onEstimate}) => {
    const rerender = useRerender();
    const [awaiting, setAwaiting] = useState(false);
    const {register, formState: {errors}, handleSubmit, watch, getValues, setValue} = useForm({
        resolver: yupResolver(schema),
        defaultValues: {...defaultValues, ...data}
    });

    const enforceResourceRatioConstraints = (data, name) => {
        const sum = resourceRatioKeys.reduce((out, key) => out + getValues(`resource.${key}`) || 0, 0);
        if (sum > 100) {
            const overage = sum - 100;
            setValue(name, getValues(name) - overage);
        }
        rerender();
    };

    watch((data, { name }) => {
        if (name.startsWith("resource.")) enforceResourceRatioConstraints(data, name);
    });

    const whenSubmitted = async data => {
        setAwaiting(true);
        try {
            const estimateData = JSON.parse(JSON.stringify(data));
            estimateData.resource.total = parseMetric(data.resource.total);
            const estimate = await Client.run("hive-estimate", estimateData);
            onEstimate(data, estimate);
        } finally {
            setAwaiting(false);
        }
    };

    return <form onSubmit={handleSubmit(whenSubmitted)}>
        <h2>Create Hive</h2>
        <div className="row mb-3">
            <div className="col-sm-6 col-xs-12">
                <label className="form-label">Client Name</label>
                <input type="text"
                       className={`form-control ${errors.clientName ? 'is-invalid' : ''}`}
                       {...register("clientName")} />
                <div className="invalid-feedback">{errors.clientName?.message}</div>
            </div>
            <div className="col-sm-6 col-xs-12">
                <label className="form-label">Hive Name</label>
                <input type="text"
                       className={`form-control ${errors.hiveName ? 'is-invalid' : ''}`}
                       {...register("hiveName")} />
                <div className="invalid-feedback">{errors.hiveName?.message}</div>
            </div>
        </div>

        <div className={"mb-3 form-check form-switch"}>
            <input type={"checkbox"} id={"banks_primary_rented"} role={"switch"}
                   className={"form-check-input"}
                   {...register("banks.primary.rented")} />
            <label className="form-check-label" htmlFor={"banks_primary_rented"}>Primary bank rented?</label>
        </div>
        <div className="mb-3">
            <label className="form-label">Primary Bank Name</label>
            <input type="text"
                   className={`form-control ${errors.banks?.primary?.name ? 'is-invalid' : ''}`}
                   {...register("banks.primary.name")} />
            <div className="invalid-feedback">{errors.banks?.primary?.name?.message}</div>
        </div>

        <div className={"mb-3 form-check form-switch"}>
            <input type={"checkbox"} id={"banks_secondary_rented"} role={"switch"}
                   className={"form-check-input"}
                   {...register("banks.secondary.rented")} />
            <label className="form-check-label" htmlFor={"banks_secondary_rented"}>Secondary bank rented?</label>
        </div>
        <div className="mb-3">
            <label className="form-label">Secondary Bank Name</label>
            <input type="text"
                   className={`form-control ${errors.banks?.secondary?.name ? 'is-invalid' : ''}`}
                   {...register("banks.secondary.name")} />
            <div className="invalid-feedback">{errors.banks?.secondary?.name?.message}</div>
        </div>

        <div className="mb-3">
            <label className="form-label">Alliance Name (leave blank to generate one)</label>
            <input type="text"
                   className={`form-control ${errors.allianceName ? 'is-invalid' : ''}`}
                   {...register("allianceName")} />
            <div className="invalid-feedback">{errors.allianceName?.message}</div>
        </div>
        <div className="row mb-3">
            <label className="form-label">Location</label>
            <div className="col-4">
                <input type="number"
                       placeholder={"State"}
                       className={`form-control ${errors.location?.z ? 'is-invalid' : ''}`}
                       {...register("location.z", {valueAsNumber: true})} />
                <div className="invalid-feedback">{errors.location?.z?.message}</div>
            </div>
            <div className="col-4">
                <input type="number"
                       placeholder={"X"}
                       className={`form-control ${errors.location?.x ? 'is-invalid' : ''}`}
                       {...register("location.x", {valueAsNumber: true})} />
                <div className="invalid-feedback">{errors.location?.x?.message}</div>
            </div>
            <div className="col-4">
                <input type="number"
                       placeholder={"Y"}
                       className={`form-control ${errors.location?.y ? 'is-invalid' : ''}`}
                       {...register("location.y", {valueAsNumber: true})} />
                <div className="invalid-feedback">{errors.location?.y?.message}</div>
            </div>
        </div>
        <div className="mb-3">
            <label className="form-label">Resource total</label>
            <input type="text"
                   className={`form-control ${errors.resource?.total ? 'is-invalid' : ''}`}
                   {...register("resource.total")} />
            <div className="invalid-feedback">{errors.resource?.total?.message}</div>
        </div>
        <div className={"mb-3"}>
            <label className="form-label">Quartz - {getValues("resource.quartz")}</label>
            <input type="range" min={0} max={100} step={1}
                   className={`form-range ${errors.resource?.quartz ? 'is-invalid' : ''}`}
                   {...register("resource.quartz", {valueAsNumber: true})} />
        </div>
        <div className={"mb-3"}>
            <label className="form-label">Rare Earth - {getValues("resource.earth")}</label>
            <input type="range" min={0} max={100} step={1}
                   className={`form-range ${errors.resource?.earth ? 'is-invalid' : ''}`}
                   {...register("resource.earth", {valueAsNumber: true})} />
        </div>
        <div className={"mb-3"}>
            <label className="form-label">Corn - {getValues("resource.corn")}</label>
            <input type="range" min={0} max={100} step={1}
                   className={`form-range ${errors.resource?.corn ? 'is-invalid' : ''}`}
                   {...register("resource.corn", {valueAsNumber: true})} />
        </div>
        <div className={"mb-3"}>
            <label className="form-label">Alloy - {getValues("resource.alloy")}</label>
            <input type="range" min={0} max={100} step={1}
                   className={`form-range ${errors.resource?.alloy ? 'is-invalid' : ''}`}
                   {...register("resource.alloy", {valueAsNumber: true})} />
        </div>
        <div className={"mb-3 form-check form-switch"}>
            <input type={"checkbox"} id={"resource_gas"} role={"switch"}
                   className={"form-check-input"}
                   {...register("resource.gas")} />
            <label className="form-check-label" htmlFor={"resource_gas"}>Prioritize gas?</label>
        </div>
        <div className="mb-3">
            <label className="form-label">Term in days</label>
            <input type={"number"}
                   className={`form-control ${errors.termLength ? 'is-invalid' : ''}`}
                   {...register("termLength")} />
            <div className="invalid-feedback">{errors.termLength?.message}</div>
        </div>
        <Button type={"submit"} disabled={awaiting}>
            {awaiting && <><span className="spinner-border spinner-border-sm" role="status"
                                 aria-hidden="true"/><span className="ms-1"/></>}
            Estimate
        </Button>
    </form>;
};

const ReviewLine = ({label, value}) => {
    return <div className={"row"}>
        <div className={"col-3"}>{label}</div>
        <div className={"col-auto"}>{value}</div>
    </div>;
};

const Review = ({data, editResult: {estimate, state}, onEdit}) => {
    const navigate = useNavigate();
    const [awaiting, setAwaiting] = useState(false);
    const whenSubmitted = async () => {
        setAwaiting(true);
        try {
            const clone = JSON.parse(JSON.stringify(data));
            clone.banks.primary.name = data.banks.primary.name ? data.banks.primary.name : state.params.banks.primary.name;
            clone.banks.secondary.name = data.banks.secondary.name ? data.banks.secondary.name : state.params.banks.secondary.name;
            clone.allianceName = data.allianceName ? data.allianceName : state.allianceName;
            clone.resource.total = parseMetric(data.resource.total);
            const info = {...clone, state};
            const result = await Client.createHive(info);
            navigate(`/hives/${result.hive.id}`);
        } finally {
            setAwaiting(false);
        }
    };

    const today = new Date();
    today.setDate(today.getDate() + data.termLength);
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const day = today.getDate() < 10 ? `0${today.getDate()}` : today.getDate()
    const monthAbbrev = months[today.getMonth()];
    const year = today.getFullYear().toString().slice(-2);
    const expirationDate = `${day}${monthAbbrev}${year}`;

    const presentation = JSON.parse(JSON.stringify(data));
    presentation.location = render(data.location);
    presentation.resource.total = numberFormat(parseMetric(data.resource.total));
    presentation.resource.quartz = `${data.resource.quartz}% - ${numberFormat(estimate.quartz)}`;
    presentation.resource.earth = `${data.resource.earth}% - ${numberFormat(estimate.earth)}`;
    presentation.resource.corn = `${data.resource.corn}% - ${numberFormat(estimate.corn)}`;
    presentation.resource.alloy = `${data.resource.alloy}% - ${numberFormat(estimate.alloy)}`;
    presentation.resource.gasAmount = numberFormat(estimate.gas);
    presentation.resource.gas = data.resource.gas ? "yes" : "no";
    presentation.allianceName = data.allianceName ? data.allianceName : `${state.allianceName} <generated>`;
    presentation.banks.primary.rented = data.banks.primary.rented ? "yes" : "no";
    presentation.banks.primary.name = data.banks.primary.name ? data.banks.primary.name : `${state.params.banks.primary.name} <generated>`;
    presentation.banks.secondary.rented = data.banks.secondary.rented ? "yes" : "no";
    presentation.banks.secondary.name = data.banks.secondary.name
        ? data.banks.secondary.name
        : state.params.banks.secondary.name ? `${state.params.banks.secondary.name} <generated>` : "";
    presentation.termLength = `${data.termLength} days, with last day of service on ${expirationDate}`;

    return <>
        <h2>Review</h2>
        <div className={"mb-3"}>
            <ReviewLine label={"Client name"} value={presentation.clientName} />
            <ReviewLine label={"Hive name"} value={presentation.hiveName} />
            <ReviewLine label={"Primary bank rented"} value={presentation.banks.primary.rented} />
            <ReviewLine label={"Primary bank name"} value={presentation.banks.primary.name} />
            <ReviewLine label={"Secondary bank rented"} value={presentation.banks.secondary.rented} />
            <ReviewLine label={"Secondary bank name"} value={presentation.banks.secondary.name} />
            <ReviewLine label={"Alliance name"} value={presentation.allianceName} />
            <ReviewLine label={"Location"} value={presentation.location} />
            <ReviewLine label={"Resource total"} value={presentation.resource.total} />
            <ReviewLine label={"Quartz"} value={presentation.resource.quartz} />
            <ReviewLine label={"Rare Earth"} value={presentation.resource.earth} />
            <ReviewLine label={"Corn"} value={presentation.resource.corn} />
            <ReviewLine label={"Alloy"} value={presentation.resource.alloy} />
            <ReviewLine label={"Gas"} value={presentation.resource.gasAmount} />
            <ReviewLine label={"Prioritize gas"} value={presentation.resource.gas} />
            <ReviewLine label={"Term"} value={presentation.termLength} />
        </div>
        <Button onClick={() => onEdit()} disabled={awaiting}>
            {awaiting && <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />&nbsp;</>}
            Edit
        </Button>
        <Button variant={"danger"} onClick={() => whenSubmitted()} disabled={awaiting}>
            {awaiting && <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />&nbsp;</>}
            Confirm & Create
        </Button>
    </>;
};

export default () => {
    const [mode, setMode] = useState("edit");
    const [data, setData] = useState({});
    const [editResult, setEditResult] = useState();

    const whenEstimate = (data, result) => {
        setMode("review");
        setData(data);
        setEditResult(result);
    };

    return <>
        {mode === "edit" && <Edit data={data} onEstimate={whenEstimate} />}
        {mode === "review" && <Review data={data} editResult={editResult} onEdit={() => setMode("edit")} />}
    </>;
};