import {Await, defer, useLoaderData, useNavigate, useParams} from "react-router-dom";
import React, { Suspense, useState } from "react";
import Client from "../remote/Client";
import { render } from "../util/location";
import { numberFormat } from "../util/formats";
import { Button, Modal } from "react-bootstrap";
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {hiveSchema} from "../util/schema";
import toast from 'react-hot-toast';

const schema = yup.object({...hiveSchema});

const Line = ({label, value}) => {
    return <div className={"row"}>
        <div className={"col-3"}>{label}</div>
        <div className={"col-auto"}>{value}</div>
    </div>;
};

const ViewSkeleton = () => {
    return <>
        <div className="containet placeholder-wave">
            <div className="row">
                <h2 className="placeholder col-5" />
            </div>
            <div className="row">
                <p className="placeholder col-4" />
            </div>
            <div className="row">
                <p className="placeholder col-5" />
            </div>
        </div>
    </>;
}

const DestroyModal = ({show, onHide, confirm, onConfirmed}) => {
    const schema = yup.object({
        confirm: yup.string().matches(confirm, { message: "Must type hive name exactly" })
    });

    const {register, formState: {errors}, handleSubmit} = useForm({
        resolver: yupResolver(schema)
    });

    const whenSubmitted = () => {
        onConfirmed();
    };

    return <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
            <Modal.Title>Confirm action</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form>
                <label className="form-label">Confirm destruction by typing in the hive name: <b>{confirm}</b></label>
                <input type="text"
                       autoComplete={"off"}
                       className={`form-control ${errors.confirm ? 'is-invalid' : ''}`}
                       {...register("confirm")} />
                <div className="invalid-feedback">{errors.confirm?.message}</div>
            </form>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>Cancel</Button>
            <Button variant="danger" onClick={handleSubmit(whenSubmitted)}>Destroy</Button>
        </Modal.Footer>
    </Modal>;
}

const RepairModel = ({show, onHide, onConfirmed}) => {
    return <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
            <Modal.Title>Confirm action</Modal.Title>
        </Modal.Header>
        <Modal.Body>Would you like to initiate repair of this hive?</Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>Cancel</Button>
            <Button variant="danger" onClick={() => onConfirmed()}>Confirm</Button>
        </Modal.Footer>
    </Modal>;
}

const View = ({data, onEdit}) => {
    const navigate = useNavigate();
    const [showDestroyModal, setShowDestroyModal] = useState(false);
    const [showRepairModal, setShowRepairModal] = useState(false);
    const [awaitingRepair, setAwaitingRepair] = useState(false);
    const [awaitingDelete, setAwaitingDelete] = useState(false);

    const handleDestroyModalClose = () => setShowDestroyModal(false);
    const confirmDestroy = () => setShowDestroyModal(true);
    const onDestroyConfirmed = async () => {
        try {
            setShowDestroyModal(false);
            setAwaitingDelete(true);
            const result = await Client.destroyHive(data.id);
            if (result.error) toast.error(result.error.message);
            else navigate(`/`);
        }
        finally {
            setAwaitingDelete(false);
        }
    };

    const handleRepairModalClose = () => setShowRepairModal(false);
    const confirmRepair = () => setShowRepairModal(true);
    const onRepairConfirmed = async () => {
        try {
            setShowRepairModal(false);
            setAwaitingRepair(true);
            const result = await Client.repairHive(data.id);
            if (result.error) toast.error(result.error.message);
        }
        finally {
            setAwaitingRepair(false);
        }
    }

    return <>
        <DestroyModal show={showDestroyModal} onHide={handleDestroyModalClose} confirm={data.hiveName} onConfirmed={onDestroyConfirmed} />
        <RepairModel show={showRepairModal} onHide={handleRepairModalClose} onConfirmed={onRepairConfirmed} />

        <div className="container-fluid">
            <div className="row">
                <div className="col-12 w-auto ms-auto">
                    <div className="btn-group" role="group">
                        <Button variant={"primary"} onClick={confirmRepair} disabled={awaitingRepair}>
                            {awaitingRepair && <span className="spinner-border spinner-border-sm"
                                                     role="status" aria-hidden="true"/>}
                            {!awaitingRepair && <i className={`bi bi-hammer`}/>}
                        </Button>
                        <Button variant={"primary"} onClick={() => onEdit()}>
                            <i className={`bi bi-pencil`} />
                        </Button>
                        <Button variant={"danger"} onClick={confirmDestroy} disabled={awaitingDelete}>
                            {awaitingDelete && <span className="spinner-border spinner-border-sm"
                                                     role="status" aria-hidden="true"/>}
                            {!awaitingDelete && <i className={`bi bi-trash`}/>}
                        </Button>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <Line label={"Primary bank rented"} value={data.banks.primary.rented ? "yes" : "no"}/>
                    <Line label={"Primary bank name"} value={data.banks.primary.name}/>
                    {data.banks.secondary && <>
                        <Line label={"Secondary bank rented"} value={data.banks.secondary.rented ? "yes" : "no"}/>
                        <Line label={"Secondary bank name"} value={data.banks.secondary.name}/>
                    </>}
                    <Line label={"Alliance name"} value={data.allianceName}/>
                    <Line label={"Location"} value={render(data.location)}/>
                    <Line label={"Resource total"} value={numberFormat(data.resource.total)}/>
                    <Line label={"Quartz"} value={`${data.resource.quartz}%`}/>
                    <Line label={"Rare Earth"} value={`${data.resource.earth}%`}/>
                    <Line label={"Corn"} value={`${data.resource.corn}%`}/>
                    <Line label={"Alloy"} value={`${data.resource.alloy}%`}/>
                    <Line label={"Prioritize gas"} value={data.resource.gas ? "yes" : "no"}/>
                </div>
            </div>
        </div>
    </>;
}

const Edit = ({data: initData, onCancel, onUpdate}) => {
    const [awaiting, setAwaiting] = useState(false);
    const {register, formState: {errors}, handleSubmit, watch, getValues, setValue} = useForm({
        resolver: yupResolver(schema),
        defaultValues: {...initData}
    });

    const whenSubmitted = async data => {
        setAwaiting(true);
        try {
            const result = await Client.updateHive(initData.id, data);
            if (result.error) toast.error(result.error.message);
            else onUpdate(result.hive);
        } finally {
            setAwaiting(false);
        }
    }

    return <form onSubmit={handleSubmit(whenSubmitted)}>
        <div className="row mb-3">
            <div className="col-sm-6 col-xs-12">
                <label className="form-label">Client Name</label>
                <input type="text"
                       className={`form-control ${errors.clientName ? 'is-invalid' : ''}`}
                       {...register("clientName")} />
                <div className="invalid-feedback">{errors.clientName?.message}</div>
            </div>
            <div className="col-sm-6 col-xs-12">
                <label className="form-label">Hive Name</label>
                <input type="text"
                       className={`form-control ${errors.hiveName ? 'is-invalid' : ''}`}
                       {...register("hiveName")} />
                <div className="invalid-feedback">{errors.hiveName?.message}</div>
            </div>
        </div>

        <div className={"mb-3 form-check form-switch"}>
            <input type={"checkbox"} id={"banks_primary_rented"} role={"switch"}
                   className={"form-check-input"}
                   {...register("banks.primary.rented")} />
            <label className="form-check-label" htmlFor={"banks_primary_rented"}>Primary bank rented?</label>
        </div>
        <div className="mb-3">
            <label className="form-label">Primary Bank Name</label>
            <input type="text"
                   className={`form-control ${errors.banks?.primary?.name ? 'is-invalid' : ''}`}
                   {...register("banks.primary.name")} />
            <div className="invalid-feedback">{errors.banks?.primary?.name?.message}</div>
        </div>

        <div className={"mb-3 form-check form-switch"}>
            <input type={"checkbox"} id={"banks_secondary_rented"} role={"switch"}
                   className={"form-check-input"}
                   {...register("banks.secondary.rented")} />
            <label className="form-check-label" htmlFor={"banks_secondary_rented"}>Secondary bank rented?</label>
        </div>
        <div className="mb-3">
            <label className="form-label">Secondary Bank Name</label>
            <input type="text"
                   className={`form-control ${errors.banks?.secondary?.name ? 'is-invalid' : ''}`}
                   {...register("banks.secondary.name")} />
            <div className="invalid-feedback">{errors.banks?.secondary?.name?.message}</div>
        </div>

        <div className="mb-3">
            <label className="form-label">Alliance Name (leave blank to generate one)</label>
            <input type="text"
                   className={`form-control ${errors.allianceName ? 'is-invalid' : ''}`}
                   {...register("allianceName")} />
            <div className="invalid-feedback">{errors.allianceName?.message}</div>
        </div>
        <div className="row mb-3">
            <label className="form-label">Location</label>
            <div className="col-4">
                <input type="number"
                       placeholder={"State"}
                       className={`form-control ${errors.location?.z ? 'is-invalid' : ''}`}
                       {...register("location.z", {valueAsNumber: true})} />
                <div className="invalid-feedback">{errors.location?.z?.message}</div>
            </div>
            <div className="col-4">
                <input type="number"
                       placeholder={"X"}
                       className={`form-control ${errors.location?.x ? 'is-invalid' : ''}`}
                       {...register("location.x", {valueAsNumber: true})} />
                <div className="invalid-feedback">{errors.location?.x?.message}</div>
            </div>
            <div className="col-4">
                <input type="number"
                       placeholder={"Y"}
                       className={`form-control ${errors.location?.y ? 'is-invalid' : ''}`}
                       {...register("location.y", {valueAsNumber: true})} />
                <div className="invalid-feedback">{errors.location?.y?.message}</div>
            </div>
        </div>
        <Button type={"submit"} variant={"danger"} disabled={awaiting}>
            {awaiting && <><span className="spinner-border spinner-border-sm"
                               role="status" aria-hidden="true"/><span className="ms-1"/></>}
            Update
        </Button>
        <span className="ms-1"/>
        <Button variant={"primary"} onClick={() => onCancel()}>Cancel</Button>
    </form>;
}

const Loaded = ({data: initData}) => {
    const [data, setData] = useState(initData);
    const [view, setView] = useState("view")
    const whenUpdated = data => {
        setData(data);
        setView("view");
    }
    return <>
        <h2>{data.clientName} - {data.hiveName}</h2>
        {view === "view"
            ? <View data={data} onEdit={() => setView("edit")}/>
            : <Edit data={data} onCancel={() => setView("view")} onUpdate={whenUpdated}/>}
    </>;
}

export const loader = async ({params}) => {
    const data = Client.hiveById(params.id);
    // const data = new Promise((resolve) => {});
    return defer({data: data});
}

export default function ManageHive(props) {
    const {id} = useParams();
    const {data} = useLoaderData();
    return <>
        <Suspense fallback={<ViewSkeleton/>}>
            <Await resolve={data}>
                {(resolved) => <Loaded data={resolved.hive}/>}
            </Await>
        </Suspense>
    </>
}