import React, {useContext} from "react";
import AuthContext from "../auth";
import "./Navigation.css"
import {Button, Nav} from "react-bootstrap";
import {NavLink} from "react-router-dom";

const Logout = () => {
    const { logout } = useContext(AuthContext)
    return <div className="d-grid">
        <Button className="btn-danger" onClick={logout}>Logout</Button>
    </div>
}

const Item = ({to, icon, name, ...other}) => {
    const useRouter = other.useRouter === undefined ? true : other.useRouter;
    const props = useRouter ? {as: NavLink, to} : {href: to};
    return <li className="nav-item">
        <Nav.Link {...props}><i className={`bi ${icon}`}></i> {name}</Nav.Link>
    </li>
};

export default () => {
    const {user, hasPermissions} = useContext(AuthContext)
    return <>
        <nav className="d-flex flex-column col-md-3 col-lg-2 bg-dark p-3 sidebar">
            <div>{user}</div>
            <hr/>
            <ul className="nav nav-pills flex-column mb-auto">
                <Item to={"/"} icon={"bi-speedometer2"} name={"Dashboard"} />
                <Item to={"/jobs"} icon={"bi-activity"} name={"Jobs"} />
                {hasPermissions("view-logs") &&
                    <Item to={"/logs"} useRouter={false} icon={"bi-file-text"} name={"Logs"} />
                }
            </ul>
            <hr/>
            <Logout />
        </nav>
    </>;
};