import React, { useEffect, useState} from "react";
import DataTables from "../components/DataTables";
import Client from "../remote/Client";
import { parseJSON, differenceInDays, differenceInSeconds, format, formatDistance } from 'date-fns';
import $ from "jquery";

export default () => {
    const [grid, setGrid] = useState()

    const foo = () => {
        console.log(this);
    }

    useEffect(() => {
        if (grid) {
            const $grid = $(grid);
        }
    }, [grid]);

    const gridConfig = {
        ajax: async (data, callback, settings) => {
            const result = await Client.hives();
            callback({data: result.hives, draw: data.draw});
        },
        serverSide: true,
        processing: true,
        paging: false,
        info: false,
        ordering: false,
        dom: "rtip",
        columns: [
            {
                data: "id",
                render: function (data, type, row, meta) {
                    return `<a href="/hives/${row.id}" class="link-primary">${row.clientName} - ${row.hiveName}</a>`
                }
            }, {
                data: "expires",
                width: 1,
                render: function(data, type, row, meta) {
                    const localNow = new Date();
                    const localDate = parseJSON(data);
                    const diff = differenceInDays(localDate, localNow);
                    const diffSeconds = differenceInSeconds(localDate, localNow)
                    const formatted =
                        diffSeconds < 0 ? "expired" : diff < 1
                            ? formatDistance(localDate, localNow)
                            : format(localDate, "yyyy-MM-dd");
                    const color = diff <= 0 ? "danger" : diff <= 5 ? "warning" : "success";
                    return `<span class="badge rounded-pill text-bg-${color}">${formatted}</span>`;
                }
            }
        ]
    }

    return <div className="container-fluid">
        <div className="row">
            <div className="col-12 w-auto ms-auto">
                <div className="btn-group" role="group">
                    <a href="/hives/new" className="btn btn-primary">
                        <i className={`bi bi-plus-lg`}></i>
                    </a>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-12">
                <DataTables className="table table-striped table-hover table-borderless nowrap"
                            onCreate={setGrid}
                            {...gridConfig}>
                    <thead>
                    <tr>
                    <th>Name</th>
                        <th>Expires</th>
                    </tr>
                    </thead>
                </DataTables>
            </div>
        </div>
    </div>
}