import React, { useEffect, useState} from "react";
import DataTables from "../components/DataTables";
import Client from "../remote/Client";
import toast from 'react-hot-toast';
import $ from "jquery";

function onControlAction(grid) { return async event => {
    event.currentTarget.disabled = true;
    try {
        const id = parseInt(event.currentTarget.getAttribute("data-id"));
        const action = event.currentTarget.getAttribute("data-action")
        const result = await (action === "start" ? Client.jobStart(id) : Client.jobStop(id));
        if (result.error) toast.error(`unable to change job status code ${result.error.statusCode}`);
        else grid.draw();
    } catch {
        toast.error("unable to change job status");
    } finally {
        event.currentTarget.disabled = false;
    }
}}

export default () => {
    const [grid, setGrid] = useState();

    useEffect(() => {
        if (grid) {
            const fn = onControlAction(grid);
            const $grid = $(grid.table().container());
            $grid.on("click", ".start", fn);
            $grid.on("click", ".stop", fn);
            grid.on("destroy.dt", () => {
                $grid.off("click", ".start", fn);
                $grid.off("click", ".stop", fn);
                grid.off("destroy.dt");
            });
        }
    }, [grid]);

    const gridConfig = {
        ajax: async (data, callback, settings) => {
            const result = await Client.jobs();
            callback({data: result.jobs, draw: data.draw});
        },
        serverSide: true,
        processing: true,
        paging: false,
        info: false,
        ordering: false,
        dom: "rtip",
        columns: [
            {
                data: "id",
                width: 1
            },
            {
                data: "hive",
                render: function(data, type, row, meta) {
                    return `${row.client} - ${row.hive}`;
                }
            },
            {
                data: "kind"
            },
            {
                data: "status"
            },
            {
                data: "status",
                width: 1,render: function(data, type, row, meta) {
                    if (row.status === "Stopped")
                        return `<button role="button" class="btn btn-primary btn-sm start" data-id="${row.id}" data-action="start"><i class="bi bi-play"></i></button>`;
                    return `<button role="button" class="btn btn-primary btn-sm stop" data-id="${row.id}" data-action="stop"><i class="bi bi-stop"></i></button>`;
                }
            }
        ]
    }

    return <div>
        <DataTables className="table table-striped table-hover table-borderless nowrap"
                    onCreate={setGrid}
                    {...gridConfig}>
            <thead>
            <tr>
                <th>Id</th>
                <th>Relation</th>
                <th>Kind</th>
                <th>Status</th>
                <th>Action</th>
            </tr>
            </thead>
        </DataTables>
    </div>
}
