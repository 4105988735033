'use strict'

import React, {useEffect, useRef} from 'react'
import DataTable from 'datatables.net-bs5';

function stripToDataTableConfig(props) {
    const {className, children, bind, ...dataTableConfig} = props;
    return dataTableConfig;
}

export default function DataTables(props) {
    const dataTableConfig = stripToDataTableConfig(props);
    const tableRef = useRef(null);

    useEffect(() => {
        const dt = new DataTable(tableRef.current, dataTableConfig);
        if (props.onCreate) props.onCreate(dt);
        return () => {
            dt.clear().destroy();
        };
    }, []);

    return <div>
        <table className={props.className} ref={tableRef} style={{width: '100%'}}>
            {props.children}
        </table>
    </div>
}
