import React, { useState, useEffect } from "react";
import {RouterProvider, createBrowserRouter} from "react-router-dom";

import AuthContext from "./auth";
import Client from "./remote/Client";

import Layout from "./components/Layout";
import Dashboard from "./pages/Dashboard";
import Create from "./pages/Create";
import Jobs from "./pages/Jobs";
import ManageHive, {loader as manageHiveLoader} from "./pages/ManageHive";

const AuthContextProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [permissions, setPermissions] = useState([]);

    const handleCallbackIfNeeded = async () => {
        if (window.location.pathname === '/auth/callback') {
            await Client.token(window.location.search);
            window.location.assign('/');
        }
    };

    const loadUserOrRedirect = async () => {
        const { user, permissions } = await Client.check();
        setUser(user);
        setPermissions(permissions);
    };

    const logout = async () => {
        await Client.logout();
        window.location.assign("/");
    };

    const hasPermissions = (check) => {
        check = typeof(check) === "string" ? [check] : check;
        return check.reduce((out, permission) => out && permissions.includes(permission), true);
    }

    useEffect(() => {
        (async () => {
            await handleCallbackIfNeeded();
            await loadUserOrRedirect();
        })();
    }, []);

    return <>
        {user &&
            <AuthContext.Provider value={{user, logout, hasPermissions}}>
                {children}
            </AuthContext.Provider>
        }
    </>;
}

const router = createBrowserRouter([
    {
        element: <Layout />,
        children: [
            { path: '/', element: <Dashboard /> },
            { path: '/hives/new', element: <Create /> },
            { path: '/jobs', element: <Jobs /> },
            {
                path: '/hives/:id',
                element: <ManageHive />,
                loader: manageHiveLoader
            }
        ]
    }
]);

function App() {
    return (
        <AuthContextProvider>
            <RouterProvider router={router} />
        </AuthContextProvider>
    );
}

export default App;